export * from './adminActuator.service';
import { AdminActuatorService } from './adminActuator.service';
export * from './adminRoom.service';
import { AdminRoomService } from './adminRoom.service';
export * from './adminRoomGroup.service';
import { AdminRoomGroupService } from './adminRoomGroup.service';
export * from './adminSensor.service';
import { AdminSensorService } from './adminSensor.service';
export * from './adminSettings.service';
import { AdminSettingsService } from './adminSettings.service';
export * from './adminUser.service';
import { AdminUserService } from './adminUser.service';
export * from './adminUserGroup.service';
import { AdminUserGroupService } from './adminUserGroup.service';
export * from './config.service';
import { ConfigService } from './config.service';
export * from './passwordReset.service';
import { PasswordResetService } from './passwordReset.service';
export * from './room.service';
import { RoomService } from './room.service';
export * from './session.service';
import { SessionService } from './session.service';
export * from './testData.service';
import { TestDataService } from './testData.service';
export const APIS = [AdminActuatorService, AdminRoomService, AdminRoomGroupService, AdminSensorService, AdminSettingsService, AdminUserService, AdminUserGroupService, ConfigService, PasswordResetService, RoomService, SessionService, TestDataService];
