import { Injectable } from '@angular/core';
import { SecurityRole, SessionService, UserDto } from 'src/app/api/client';
import * as Sentry from '@sentry/angular';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private sessionService: SessionService) {
    const user = this.getUserFromSession();
    if (user) {
      Sentry.setUser({
        id: user.id,
        username: user.username,
        email: user.email,
        fullName: user.name,
      });
    }
  }

  /**
   * Check whether a user is logged in.
   * @returns true, if a user is signed in.
   */
  public async isAuthenticated(): Promise<boolean> {
    return !!this.getJwtToken();
  }

  public hasRole(role: SecurityRole): boolean {
    const user = this.getUserFromSession();
    return (
      user.roles.includes(role) ||
      user.roles.includes(SecurityRole.Administrator)
    );
  }

  public invalidateSession() {
    localStorage.removeItem('session.user');
    localStorage.removeItem('session.token');
    localStorage.removeItem('session.refresh-token');
    Sentry.setUser(null);
  }

  public writeSessionData(user: UserDto, token: string, refreshToken: string) {
    localStorage.setItem('session.user', JSON.stringify(user));
    localStorage.setItem('session.token', token);
    localStorage.setItem('session.refresh-token', refreshToken);
    Sentry.setUser({
      id: user.id,
      username: user.username,
      email: user.email,
      fullName: user.name,
    });
  }

  public getUserFromSession(): UserDto {
    const userString = localStorage.getItem('session.user');
    try {
      return JSON.parse(userString);
    } catch (error) {
      return null;
    }
  }

  public getJwtToken(): string {
    return localStorage.getItem('session.token');
  }

  public getRefreshToken(): string {
    return localStorage.getItem('session.refresh-token');
  }

  public async impersonateUser(userId: number) {
    const result = await firstValueFrom(
      this.sessionService.apiV1SessionImpersonateUserIdPost(userId)
    );
    localStorage.setItem(
      'session.impersonatedBy',
      JSON.stringify(this.getUserFromSession())
    );
    this.writeSessionData(result.user, result.token, result.refreshToken);
  }

  public async stopImpersonate() {
    const result = await firstValueFrom(
      this.sessionService.apiV1SessionStopImpersonatePost()
    );
    this.writeSessionData(result.user, result.token, result.refreshToken);
    localStorage.removeItem('session.impersonatedBy');
  }

  public isImpersonating(): boolean {
    return !!localStorage.getItem('session.impersonatedBy');
  }
}
