/**
 * Templado API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SettingControlTypeDto = 'TextBox' | 'PasswordField' | 'CheckBox' | 'TimePicker';

export const SettingControlTypeDto = {
    TextBox: 'TextBox' as SettingControlTypeDto,
    PasswordField: 'PasswordField' as SettingControlTypeDto,
    CheckBox: 'CheckBox' as SettingControlTypeDto,
    TimePicker: 'TimePicker' as SettingControlTypeDto
};

