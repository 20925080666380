import {HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {mergeMap, Observable, tap, throwError} from 'rxjs';
import {catchError, filter} from 'rxjs/operators';
import {UserService} from '../user/user.service';
import {SessionDto} from "../../api/client";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private static readonly loginUrl = '/login';

  constructor(private userService: UserService, private router: Router) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const requestWithAuthorization = this.addCredentials(request);
    return next.handle(requestWithAuthorization)
      .pipe(
        filter((event) => event instanceof HttpResponse),
        catchError((error) => {
          return this.handleError(error, requestWithAuthorization, next);
        }),
      )
  }

  private handleError(error: any, originalRequest: HttpRequest<any>, next: HttpHandler) {
    if (error.status === 401) {
      // Unauthorized. Try to refresh the session.
      return this.refreshSession(next)
        .pipe(
          mergeMap(_ => next.handle(this.addCredentials(originalRequest))),
          catchError(error => {
            if (error.status === 401) {
              // Still unauthorized. Redirect to login page.
              this.userService.invalidateSession();
              this.router.navigateByUrl(AuthInterceptor.loginUrl);
              return null;
            } else {
              return throwError(() => error);
            }
          })
        );
    } else {
      return throwError(() => error);
    }
  }

  private refreshSession(handler: HttpHandler): Observable<any> {
    const refreshToken = this.userService.getRefreshToken();
    if (refreshToken) {
      return new HttpClient(handler)
        .post('/api/v1/session/refresh', null, {headers: {'Authorization': `Bearer ${refreshToken}`}})
        .pipe(
          tap((result: SessionDto) => {
            this.userService.writeSessionData(
              result.user,
              result.token,
              result.refreshToken
            );
          })
        );
    }
  }

  private addCredentials(request: HttpRequest<any>) {
    return request.clone({
      headers: request.headers.set(
        'Authorization',
        `Bearer ${this.userService.getJwtToken()}`
      ),
    });
  }

}
