import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, from, map } from 'rxjs';
import { UserService } from '../user/user.service';

type AuthGuardResult =
  | boolean
  | UrlTree
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>;

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  counter: number = 0;

  constructor(private router: Router, private userService: UserService) {}

  public canActivate(state: RouterStateSnapshot): AuthGuardResult {
    return from(this.userService.isAuthenticated()).pipe(
      map((isAuthenticated) => {
        if (isAuthenticated) {
          return true;
        }
        return this.handleAuthenticationFailed(state);
      })
    );
  }

  public canActivateChild(state: RouterStateSnapshot): AuthGuardResult {
    return this.canActivate(state);
  }

  public canActivateLogin(route: ActivatedRouteSnapshot): AuthGuardResult {
    return from(this.userService.isAuthenticated()).pipe(
      map((isAuthenticated) => {
        if (!isAuthenticated) {
          // Not logged in. Login page can show up.
          return true;
        }

        // Already logged in.
        // Prevent login page to show up
        // Redirect to the return URL.
        const dest = route.queryParams.return || '/';
        console.log('Already logged in. Redirecting to ' + dest);
        return this.router.parseUrl(dest);
      })
    );
  }

  private handleAuthenticationFailed(state: RouterStateSnapshot): UrlTree {
    console.log(`No user logged in. Redirecting to login.`);
    return this.router.createUrlTree(['/login'], {
      queryParams: { return: state.url },
    });
  }
}
