import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppUpgradeService {

  private window: Window = window;

  constructor() {
  }

  public async runUpgrade(): Promise<void> {
    try {
      const response = await fetch('/api/v1/config/version');
      const serverVersion = await response.text();
      if (serverVersion === globalThis.appVersion) {
        // Version check passed. Nothing to do.
        return;
      }

      const lastUpgradeAttempt = Number.parseInt(localStorage.getItem('lastUpgradeAttempt'));
      let lastUpgradeAttemptRetryCount = Number.parseInt(localStorage.getItem('lastUpgradeAttemptRetryCount'));
      if (!Number.isInteger(lastUpgradeAttemptRetryCount)) {
        lastUpgradeAttemptRetryCount = 0;
      }

      const millisecondsSinceLastTry = Date.now() - lastUpgradeAttempt;
      if (Number.isInteger(lastUpgradeAttempt) && millisecondsSinceLastTry < 10_000) {
        if (Number.isInteger(lastUpgradeAttemptRetryCount) && lastUpgradeAttemptRetryCount >= 3) {
          alert(`Failed to update the app to the latest version. Installed: ${globalThis.appVersion}, Latest: ${serverVersion}. ` +
            `Please try again later. If the problem persists, contact your system administrator.`);
          return;
        }
      } else {
        // First retry within given timespan.
        lastUpgradeAttemptRetryCount = 0;
      }
      localStorage.setItem('lastUpgradeAttempt', `${Date.now()}`);
      localStorage.setItem('lastUpgradeAttemptRetryCount', `${lastUpgradeAttemptRetryCount + 1}`);
      (this.window.location as any).reload(true);
    } catch (error) {
      console.error('Error fetching app version:', error);
    }
  }
}
