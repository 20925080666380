import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DebugClockService } from './services/util/debug-clock.service';
import { addIcons } from "ionicons";
import { alertCircleOutline, checkmarkCircleOutline, close } from "ionicons/icons";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(route: ActivatedRoute, debugClock: DebugClockService) {
    // The icons are used inside toasts.
    addIcons({close, alertCircleOutline, checkmarkCircleOutline});
    route.queryParamMap.subscribe((params) => {
      if (params.get('clock')) {
        debugClock.show();
      }
      // Do not hide clock again in order to keep it visible after navigation to another page
    });
  }
}
